::-webkit-scrollbar {
	width: 13px;
}

::-webkit-scrollbar-track {
	background-color: rgb(235, 235, 235);
	box-shadow: inset 0 0 3px rgb(200, 200, 200); 
}

::-webkit-scrollbar-thumb {
	cursor:pointer;
	border-radius: 9px;
	border: 2px solid transparent;
	background: rgb(100, 100, 100);
	background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
	border-radius: 9px;
	border: 1px solid transparent;
	background: rgb(80, 80, 80);
	background-clip: content-box;
}