
.glass{
	/* border: 1px red dashed; */
	min-width: max(500px, 60vw);
	max-width: 60vw;
	height: 80vh;
	background-color: white;

	background: linear-gradient(
						to right bottom, 
						rgba(255,255, 255, 0.4), 
						rgba(255, 255,255,0.8)
					);
	border-radius:  1.5rem;
	display: flex;
	flex-direction: column;

	overflow-x: hidden;
	overflow-y: auto;
}

.header{
	margin: 0.75em;
	padding: 0.75em;
	min-width: 200px;
	display: flex;
	flex-flow: row wrap;
	border-bottom: 2px rgb(0, 123, 255) solid;
	border-bottom-left-radius: 1.5em;
}
.dp{
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.dp_border{
	border: 1px solid black;
}


.intro{
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profileContent{
	display: flex;
	flex-flow: row wrap;
	margin-top: 2em;
}

.index{
	flex: 1;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	text-align: center;
}

.details{
	flex: 2;
	display: flex;
	flex-flow: column wrap;
	/* border: 1px red dashed; */
	align-items: center;
	justify-content: space-around;
}

.details div{
	min-width: 70%;
}

.profileTabContent{
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

/* profile structure */
.profileRow {
	font-size: 110%;
	padding: 10px;
	display: flex;
	flex-flow: row;
	justify-content: center;
}

/* Upload image */
.container{
	display: flex;
	flex-flow: column nowrap;
}

.uploadError{
	background-color: rgba(255, 145, 145, 0.2);
	width: 100%;
	padding: 5px;
	margin-bottom: 10px;
	border: red 2px dashed;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}


/* Recent Activity */

.activityContainer {
	/* border: 2px red solid; */
	width: max(250px, 95%);
	height: fit-content;
	margin: auto;
}

.activities {
	/* border: 2px black solid; */
	min-height: 550px;
	max-height: 550px;
	overflow-y: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.oneActivity {
	width: 90%;
	padding: 10px;
	margin: 10px 0px;
	border-radius: 20px;
	background-color: white;
	
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}


.oneActivityEx {
	font-weight: 600;
	cursor: pointer;
}
.oneActivityEx:hover {
	color: var(--primary-color);
}

.oneActivityCh {
	color: darkgray;
	font-weight: 600;
	cursor: pointer;
}
.oneActivityCh:hover {
	color: var(--primary-color);
}

.oneActivityTime {
	margin-left: 5px;
	min-width: 80px;
	max-width: 80px;
	min-height: 100%;
	
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	border-left: lightgray 3px solid;

	color: gray;
	font-size: 16px;
	font-weight: 500;
}