.ansMain{
	width: fit-content;
	min-width: 500px;
	max-width: 100%;
	/* border: solid red 1px; */
	margin: auto;

	max-height: 75vh;
	overflow-y: auto;
}

.ansSection{
	line-height: 1.5;
	
	margin-left: 50px;
	margin-bottom: 10px;

	/* border: solid red 1px; */
}