@font-face {
	font-family: Praja;
	src: url(./fonts/praja.ttf) format('truetype');
}

/* * {
	font-family: Praja;
	font-size: 102%;
} */

.App {
	height: 100vh;
	overflow: auto;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 50px 50px auto;
	grid-template-areas: 
		"navbar"
		"alertMain"
		"main";

	/* border: 3px dashed black; */
	
	/* background-image: url('static/pop.png'); */
	background-image: url('static/pop.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: 100% 100%;
}

.navbarcss {
	grid-area: navbar;
	min-height: 50px;
	
	background-color: #1d1d1d;
}

.navLink {
	color: rgb(255, 0, 0);
}

.main {
	grid-area: main;

	/* border: 5px dashed greenyellow; */
	
	display: grid;
	justify-items: center;
}

.alertMain{
	grid-area: alertMain;
	/* border: 5px dashed black; */
	margin-top: 5px;
	height: 50px;
	width: 250px;
	text-align: center;

	justify-self: center;
}
