.modal-90w{
	max-width: 90vw !important;
	min-width: 500px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

.emailsArea{
	margin-top: 5px;
	padding: 10px 20px;
	display: flex;
	flex-flow: column wrap;
	max-height: 280px;
	overflow-x: auto;
}

.studentsArea{
	width: max(500px, 70vw);
	height: max(450px,65vh);

	/* glass UI */
	background-color: white;
	background: linear-gradient(
						to right bottom, 
						rgba(255,255, 255, 0.4), 
						rgba(255, 255,255,0.9)
					);
	border-radius:  1.5rem;

	/* general */
	margin: auto;
	padding: 10px;

	/* Display */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;

	overflow-y: auto;
}

.studentBox{
	width: 200px;
	/* height: fit-content; */
	padding: 10px;
	margin: 10px;

	display: flex;
	flex-flow: column wrap;
	align-items: center;

	background-color: white;
	border-radius: 10%;
	/* border: 1px solid black; */
	text-align: center;

	color: black;
	text-decoration: none;
}
.studentBox:hover .dp {
	transform: scale(1.1);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.studentBox:hover {
	color: black;
	cursor: pointer;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dp {
	height: 100px;
	width: 100px;
	border-radius: 50%;
}
.utype{
	margin-top: 5px;
	color: var(--primary-color);
	font: bold;
}
.text{
	word-wrap: break-word;
	width: 100%;
}

.chKey{
	font-size: 140%;
}
.exKey{
	font-size: 130%;
}
.Name{
	font-size: 110%;
}
.percentage{
	color: var(--primary-color);
	font-weight: 800;
	font-size: 130%;
}

/* Students Header */
.studentHeader{
	width: 100%;
	padding: 0px 5px 10px 5px;
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;

	align-items: center;
	justify-content: space-between;
	/* background-color: rgb(202, 210, 218); */
}
.studentTitle{
	text-align: center;
	height: fit-content;
	width: max(15vw, 200px);

	font-size: 150%;
	font-weight: 500;
	word-wrap: break-word;
}
.studentCenter{
	margin-left: 10px;
	height: fit-content;
	width: max(15vw, 250px);
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
}
.studentCountSent{
	text-align: center;
	height: fit-content;
	width: max(15vw, 200px);
	/* width: 9vw; */
	font-size: 130%;
}
.studentCount{
	color: var(--primary-color);
	font-weight: 600;
	font-size: 110%;
	margin-right: 5px;
}
.footButtons{
	display: flex;
	justify-content: space-between;
	width: 100%;

	padding: 10px 20px;
}

/* Search and filter dropdown */
.searchContainer{
	width: 100%;
	display: inline-flex;
	border-bottom: 2px solid var(--primary-color);
	border-radius: 10px;
}
.searchIcon{
	color: var(--primary-color);
	size: 5em;
	margin: 0.7em;
}
.searchInput{
	width: 95%;
	height: 40px;
	font-size: 130%;
	outline: none;
	border: none;
	background-color: transparent;
}

.filtersWhole {
	margin-left: 15px;
}
.filterIcon {
	padding: 5px;
	border-radius: 50%;
	/* border: solid 1px var(--primary-color); */
	background-color: white;
}

.filterBox{
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	min-height: 100px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
}

.filtersWhole:hover .filterBox{display: block;}
.filtersWhole:hover .filterIcon{transform: scale(1.2);}


.filterBox{
	padding: 5px
}

.filterStudentType{
	display: flex;
	flex-flow: column nowrap;
}
.studentOption{
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
.studentOption label:hover{
	background-color: #dfdfdf;
}
.studentOption input{
	margin-left: 15px;
}
.studentOption label{
	margin-left: 5px;
	flex-grow: 1;
}
