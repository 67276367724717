/* Form Button & ErrorMsg */

.btnContainer {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	width: 100%;
	padding: 3vh 0;
}
  
.btnContainer p {
	margin: 14px 0 0 0;
	text-align: right;
}
  
.btnContainer p span {
	color:  rgb(0, 123, 255);
	font-weight: 600;
	letter-spacing: 0.5px;
	margin-left: 5px;
	cursor: pointer;
	transition: all 400ms ease-in-out;
}
  
.btnContainer p span:hover {
	color: rgb(1, 83, 170);
}
  
.errorMsg {
	color: red;
	font-size: 16px;
}

/* Login/Register FORM */
.formItem{
	margin-top: 3vh;
	width: 100%;
	position: relative;
	height: 60px;
	overflow: hidden;
	background-color: transparent;
}

.formItem input{
	width: 100%;
	height: 100%;
	color: #595f6e;
	padding-top: 20px;
	border: none;
	outline: none;
}

.formItem label{
	position: absolute;
	bottom: 0px;
	left:0px;
	height: 100%;
	width: 100%;
	pointer-events: none;
	border-bottom: 1px solid black;
}

.formItem label::after {
	content: "";
	position: absolute;
	left: 0px;
	bottom: -2px;
	height: 100%;
	width: 100%;
	border-bottom: 3px solid rgb(0, 123, 255);
	transform: translateX(-100%);
	transition: transform 0.3s ease;
}

.content-name{
	position: absolute;
	bottom: 5px;
	left: 0px;
	transition: all 0.3s ease;
}

.formItem input:focus + .label-name  .content-name,
.formItem input:valid +.label-name .content-name {
	transform: translateY(-120%);
	font-size: 14px;
	color: rgb(0, 123, 255);
}

.formItem input:focus + .label-name::after, 
.formItem input:valid + .label-name::after {
	transform: translateX(0%);
}

/*  */
.wholeForm{
	width: max(40vw, 300px);
	display: flex;
	flex-flow: column;
	align-items: center;
}
.innerForm{
	width: max(20vw, 300px);
	/* display: flex; */
}