.lessMargins{
	/* border: 1px red solid; */
	margin-bottom: -5px;
}

.mainLabel{
	font-size: 20pt;
	font-family: 'Praja';
}
.subLabel{
	margin: 0px;
	margin-top: -10px;
	margin-left: 15px;
}