.sidebar {
	margin-top: 47px;
	position: fixed;
	height: 100vh;
    z-index: 1000;
    background-color: #343a40;
}

.stayLeft{
    float: left;
}
.stayRight{
    float: right;
}

.toggle-off {
	margin-top: 60px;
	position: fixed;
	justify-content: center;
	z-index: 1000;
}

.icon-cog {
    color: white;
    transform: scale(2);  
    
    height: 20px;
    width: 20px;
    padding: 3px;
    margin-top: 20px;
    margin-left: 23px;
    
    border-radius: 5px;

    background-color: #1d1d1d;
}

.sideBack{
    z-index: 2;
    height:100vh; 
    width:100vw;
    background-color:rgba(0,0,0,0.5);
    transition: ease-in-out;
}

/* BHATTI */
.glassArea{
	width: max(500px, 70vw);
	height: max(100px,30vh);

	/* glass UI */
	background-color: white;
	background: linear-gradient(
						to right bottom, 
						rgba(255,255, 255, 0.4), 
						rgba(255, 255,255,0.9)
					);
	border-radius:  1.5rem;

	/* general */
	margin: auto;
	padding: 10px;

	/* Display */
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
}

.helpLinks{
	max-height: 40vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.name-deva{
	font-size: 28px;
	font-family: 'Praja';
}
