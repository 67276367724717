
.commonBox {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px 30px 5px;
}

.containerBox {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	
	margin: 0px 5px;
}

.boxTitle {
	display: flex;
	flex-direction: column;
	font-size: medium;
	text-align: center;
	margin-bottom: 0.5em;
}

.numberAndTitle{
	display: flex;
	justify-content: flex-start;
}

.line {
	height: 1px;
	width: 80%;
	align-self: center;
	color: lightslategray;
	background: lightslategray;
	margin-left: auto; margin-right: auto;
}

.smText {
	font-size: 14px;
}

.redText{
	font-size:x-small;
	color:rgb(255, 0, 0);
}

/* Used in Translation */
.detailsBox{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: var(--boxWidth);
	max-width: var(--boxWidth);

	height: fit-content;
	
	padding: 0.5em;
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	background: white;
}

.detailsBox div{
	max-width: 100%;
	overflow-wrap: break-word;
	/* border: 1px dashed red; */
}

.boxLabel {
	margin-top: 10px;
	min-width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.form-group{
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-flow: column;
}

.common-field {
	min-width: 200px;
	max-width: 200px;
	margin: 0px 5px;
}

/* LOADING */
.loading-container {
	position: absolute;
	top: 0; left: 0;

	width: 100%;
	height: 100%;

	z-index: 9999;
	background-color: rgba(255, 255, 255, 0.5);

	display: flex;
	justify-content: center;
	align-items: center;
}

/* Toast for interface error */
.toastContainer {
	z-index: 1;
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	
	background-color: rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);

	display: flex;
	align-items: center;
	justify-content: center;
}
.toast {
	font-size: 18px;
	font-weight: bold;
}
.toastBody {
	color: white;
	font-size: 16px;
}